$(function() {
	'use strict';

	// $.event.props.push('dataTransfer');
	// use ev.originalEvent.dataTransfer instead

	let defaults = {
		inputClass: null,
		inputName: 'files[]',
		placeholder: 'Drag or select a file to upload.',
		attributes: {},
		removeIcon: 'fa-close',
		maxAmount: 1,
		allowedTypes: 'image.*',
		maxSize: 1024 * 1024 * 4, // 4 MB
		onUpload: () => {},
		onRemove: () => {},
		onMaxAmountReached: () => {},
		onOutdatedBrowser: () => {},
		onError: () => {},
	};

	// Project specific
	defaults = $.extend(defaults, {
		onMaxAmountReached: function(max = 1) {
			information.generate({
				content: `Du kan endast ladda upp ${max} bild(er).`,
				type: 'notice'
			});
		},
		onOutdatedBrowser: function() {
			information.generate({
				content: 'Din webbläsare stödjer inte File Uploader. Var vänlig börja använda en uppdaterad webbläsare.',
				type: 'error'
			});
		},
		onError: function(msg) {
			information.generate({
				content: msg,
				type: 'error'
			});
		}
	});

	class DragDrop {
		constructor(self, options = {}) {
			this.settings = this.s = $.extend(true, {}, defaults, options);
			this.$ = $(self);

			if(this.$.length === 0) {
				//console && console.log('No dropzone present');
				return this.$;
			}

			this.files = {};
			this.regExp = new RegExp(this.s.allowedTypes);

			this.$.data('dragdrop', this);

			this.$content = $('<div />').addClass('text').text(this.s.placeholder).appendTo(this.$);
			$('<label>Välj filer</label>').attr({
				class: 'fileUpload fa-file-image-o'
			}).appendTo(this.$);

			this.$input = $('<input />').attr($.extend({
				type: 'file',
				name: this.s.inputName,
				class: this.s.inputClass,
				multiple: this.s.maxAmount > 1
			}, this.s.attributes)).appendTo(this.$);

			this.events();
			return this.$;
		}

		appendFile(file) {
			let content = '<p class="item">\
							<span class="name" title="{0}">{0}</span> {1}\
							<span class="fa {2}"></span>\
						</p>'.format(file.name, this.humanSize(file.size), this.s.removeIcon);
			this.$content.append(content);
		}

		events() {
			this.$
				.on('dragover', () => {
					this.$.addClass('active');
					return false;
				})
				.on('dragend dragleave', () => {
					this.$.removeClass('active');
					return false;
				})
				.on('click', () => {
					this.$input.trigger('click');
				})
				.on('drop', ev => {
					ev.preventDefault();
					this.$.removeClass('active');

					if(typeof ev.originalEvent.dataTransfer.files !== 'object') {
						this.s.onOutdatedBrowser();
					} else {
						let files = ev.originalEvent.dataTransfer.files;
						this.loop(files);

						if(files.length) {
							this.$input.trigger('change', true);
						}
					}
				});

			this.$.closest('form').on('submit', ev => {
				let $this = $(ev.currentTarget),
					$files = $('#dropzone-files');

				$files.length && $files.remove();

				$('<input type="hidden" name="dropzone-files" id="dropzone-files" />').val(JSON.stringify(this.getFiles())).appendTo($this);
			});

			this.$.on('click', '.text .fa', ev => {
				let $item = $(ev.currentTarget).closest('.item'),
					name = $item.find('.name').text();

				this.s.onRemove(this.files[name]);
				delete this.files[name];

				$item.remove();

				if(this.$content.find('.item').length === 0)
					this.$content.text(this.s.placeholder);

				ev.stopPropagation();
			});

			this.$input
				.on('click', ev => {
					ev.stopPropagation();
				})
				.on('change', (ev, external = false) => {
					let files = this.$input[0].files;

					if(files.length && !external) {
						this.loop(files);
					}
				});
		}

		setFileData(name, data) {
			for(let file in this.files) {
				if(!this.files.hasOwnProperty(file)) continue;
				this.files[file].data = data;
			}
			this.$.data('files', this.files);
		}

		getFiles() {
			return this.files;
		}

		loop(files) {
			let items = this.$content.find('.item').length;
			if(items === 0) this.$content.empty();

			$.each(files, (key, file) => {
				if(items + key + 1 > this.s.maxAmount) {
					this.s.onMaxAmountReached(this.s.maxAmount);
					return false;
				}

				if(file.size > this.s.maxSize) {
					this.s.onError('{0} has a size which exceeds the max size of {1}'.format(file.name, this.humanSize(this.s.maxSize)));
					return false;
				}
				if(!file.type.match(this.regExp)) {
					this.s.onError('{0} does not match the allowed file types.'.format(file.name));
					return false;
				}

				this.appendFile(file);
				this.files[file.name] = {
					name: file.name,
					type: file.type,
					size: file.size
				};

				this.process(file)
					.then(image => {
						$.extend(this.files[file.name], {
							image: image,
							data: image.src,
							dataLength: image.src.length,
							width: image.width,
							height: image.height
						});
						// this.files[file.name].data = image.src;
						// this.files[file.name].dataLength = image.src.length;
					})
					.catch(err => {
						console.error(err);
					});
			});

			this.$.data('files', this.files);
		}

		process(file) {
			return new Promise((resolve, reject) => {
				let reader = new FileReader(),
					image = new Image(),
					dataUrl = null;

				reader.onerror = ev => {
					reject("File could not be read! Code " + ev.target.error.code);
				};

				reader.onload = ev => {
					dataUrl = ev.currentTarget.result;
					this.s.onUpload(file, dataUrl);
				};

				reader.onloadend = () => {
					image.src = dataUrl;
					image.onload = () => {
						resolve(image);
					};
				};

				reader.readAsDataURL(file);
			});
		}

		humanSize(bytes, decimals = 1) {
			let sz = 'BKMGTP',
				factor = Math.floor((bytes.toString().length - 1) / 3);
			return "{0}{1}b".format((bytes / Math.pow(1024, factor)).toFixed(decimals), sz[factor]);
		}
	}

	$.fn.dragDrop = function(options) {
		return new DragDrop(this, options);
	};
});